.sub-admin-full-layout{
    height: 100vh;
    overflow-y: hidden;

    .left{
        width: 550px;
        height: 100vh;

        img{
            width: 100%;
            object-fit: cover;
            object-position: center;
            height: 100%;
        }
    }

    .right{
        background-color: $theme-background;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;   

        h5{
            color: $theme-blue;
              
        }
    }
}
