.main-layout{
    background-color: $theme-background;
    min-height: 100vh;
}

.sub-admin-main-layout{
    min-height: 100vh !important;

    .box {
        width: 150px;
        border-radius: 5px;
        background-color: $theme-white;
        margin-left: auto;
        cursor: pointer;

        span {
            color: $theme-orange;
        }
    }
}
