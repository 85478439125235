@media screen and (max-width: 1400px) {
    .add-group-page {
        .left {
            .add-box-container {
                height: 280px;
            }
        }
    }
}

@media screen and (max-width: 1200px) {

    .current-session-description {
        .right {
            .div-img{
                height: 280px;
            }
        }
    }

    .client-history-page {
        .search-container {
            .holder {
                h6 {
                    font-size: 14px;
                }
            }
        }
    }

    .signin-form {
        h2 {
            font-size: 32px;
        }
    }

    .payment-check-card {
        h4 {
            font-size: 20px;
        }
    }
}

@media screen and (max-width: 1220px) {
    .client-history-page {
        .search-container {
            .holder {
                h6 {
                    font-size: 13px;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    h2,
    h2 {
        font-size: 27px;
    }

    button,
    .btn,
    .btn-primary {
        font-size: 14px;
    }

    .auth-layout {

        .left{
            min-width: 340px;
        }
        
        .right {
            .otp-input {
                input {
                    height: 45px;
                }
            }
        }
    }

    .subscripion-card {
        h4 {
            font-size: 20px;
        }

        ul {
            padding-left: 0;
            li {
                list-style: none;
                // font-size: 12px;
            }
        }
    }

    .manage-group-page {
        .right,
        .left {
            height: auto;
        }

        .box,
        .box-small {
            h6 {
                font-size: 14px;
            }
        }
    }

    .add-group-page {
        .left {
            .add-box-container {
                height: 260px;
            }
        }
    }

    .pdf-viewer {
        height: auto;
    }

    .current-session-description {
        .right {
            .content-container {    
                .title{
                    width: 550px;
                }
            }

            .div-img{
                height: auto;
            }
        }
    }

    .general-file-viewer {
        height: 100%;

        #proxy-renderer {
            background: white;
        }

        #react-doc-viewer{
            height: 100vh;
        }
    }
}

@media screen and (max-width: 768px) {
    .payment-check-card {
        background-color: $theme-background;
        border-radius: 15px;

        h6 {
            font-size: 14px;

            &.type-heading {
                width: 50px;
            }
        }

        h4 {
            font-size: 16px;
            font-weight: 600;
        }
    }

    .setting-page {
        .nav-container {
            min-height: auto;
        }
    }

    .email-template-page {
        .left {
            height: auto;
        }
    }

    .privacy-policy,
    .terms-and-condition {
        .h-100vh {
            height: auto;
        }
    }

    .email-template-page {
        .right {
            background-color: $theme-white;
            border-radius: 15px;
            height: auto;
        }
    }

    .sub-admin-subscription-upgrade-page {
        height: auto;
    }

    .auth-layout {
        .right {
            .otp-input {
                input {
                    height: 50px;
                }
            }
        }
    }

    .current-session-description {
        .right {
            .content-container {    
                .title{
                    width: 400px;
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .current-session-description {
        .right {
            .content-container {    
                .title{
                    width: 300px;
                }
            }
        }
    }

    .modal-dialog {
        margin: 0.8rem 1.25rem !important;
    }

    .profile-modal,
    .notification-modal {
        .modal-dialog {
            margin: 10px;
        }

        .modal-body {
            .profile-container,
            .notification-container {
                height: auto;
            }
        }
    }

    .box,
    .box-small {
        h6 {
            font-size: 14px !important;
        }
    }
}

@media screen and (max-width: 515px) {
    .logout-modal,
    .delete-account-modal {
        .modal-body {
            button,
            .btn,
            .btn-primary {
                width: 100%;
                margin-bottom: 1rem;
            }
        }
    }

    .calendar-modal {
        .react-calendar__navigation {
            button {
                &:nth-child(1) {
                    background: transparent;
                }

                &:nth-child(3) {
                    span {
                        font-size: 18px;
                    }
                }

                &:nth-child(5) {
                    background: transparent;
                }
            }
        }

        .react-calendar__tile {
            background-color: transparent;
            margin: 0;
        }
    }
}

@media screen and (max-width: 545px) {
    .add-client-popup {
        .modal-body {
            .content-container{
                button,
                .btn,
                .btn-primary{
                    width: 100%;
                    margin-bottom: 1rem;
                }
            }
        }
    }

    .calendar-modal {    
        .react-calendar__navigation {
            button {
                &:nth-child(3) {
                    span{
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .current-session-description {
        .right {
            .content-container {    
                .title{
                    width: 240px;
                }
            }
        }
    }

    h2,
    h2 {
        font-size: 24px;
    }

    .subscripion-card {
        h4 {
            font-size: 16px;
        }
    }

    .audio-modal,
    .custom-player {
        .btn-prev,
        .btn-next {
            width: 30px;
            height: 30px;
        }

        .btn-play {
            width: 40px;
            height: 40px;
        }

        .btn-repeat {
            width: 30px;
            height: 30px;
        }
    }

    .notification-modal {
        .modal-body {
            .notification-container {
                h6 {
                    font-size: 14px;
                }

                p {
                    font-size: 10px;
                }

                .notification-card {
                    .name-container {
                        width: 35px;
                        height: 35px;
                    }
                }
            }
        }
    }

    .auth-layout {
        .right {
            .otp-input {
                input {
                    height: 45px;
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .pdf-card-small,
    .video-card-small,
    .image-card-small,
    .audio-card-small {
        .mb-custom {
            margin-bottom: 0.5rem;
        }
        .sub-div {
            flex-direction: column;
            justify-content: flex-start !important;
            align-items: flex-start !important;

            h6,
            button {
                width: 100%;
                text-align: start;
                margin: 0;
                margin-left: 0 !important;
            }

            h6{
                width: 150px;
            }
        }
    }

    .custom-player {
        .btn-prev,
        .btn-next {
            width: 25px;
            height: 25px;
        }

        .btn-play {
            width: 40px;
            height: 40px;
        }

        .btn-repeat {
            width: 25px;
            height: 25px;
        }
    }
}

@media screen and (max-width: 350px) {
    .box,
    .box-small {
        h6 {
            font-size: 11px !important;
        }
    }

    .audio-modal,
    .custom-player {
        .btn-prev,
        .btn-next {
            width: 25px;
            height: 25px;
        }

        .btn-play {
            width: 40px;
            height: 40px;
        }

        .btn-repeat {
            width: 25px;
            height: 25px;
        }
    }

    .header {
        .circle-div {
            width: 40px;
            height: 40px;
        }
    }
}

@media screen and (max-height: 570px) {
    .add-group-page {
        .left {
            .add-box-container {
                height: 280px;
            }
        }
    }
}

@media screen and (max-height: 500px) {
    .add-group-page {
        .left {
            .add-box-container {
                height: 250px;
            }
        }
    }
}

@media screen and (max-height: 460px) {
    .add-group-page {
        .left {
            .add-box-container {
                height: 100%;
            }
        }
    }
}

@media screen and (min-width: 992px){
    .general-file-viewer{
        #react-doc-viewer{
            height: 100%;
        }
    }
}