.notification-modal {
    .modal-content {
        border-radius: 25px;
    }

    .modal-dialog {
        margin-right: 30px;
        margin-top: 100px;
    }

    .modal-body {
        .header {
            h6 {
                font-weight: 500;
                color: $theme-black;

                span {
                    font-size: 18px;
                    color: $theme-orange;
                    font-weight: 500;
                }
            }

            .btns-time {
                cursor: pointer;
                background-color: $theme-light-grey;
                border-radius: 50%;
                width: 25px;
                height: 25px;
            }
        }

        .notification-container {
            height: calc(100vh - 220px);
            overflow-y: scroll;

            &::-webkit-scrollbar {
                // width: 3px;
                display: none;
            }

            &::-webkit-scrollbar-track {
                background: $theme-light-grey;
            }

            &::-webkit-scrollbar-thumb {
                background: $theme-grey;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: $theme-black;
            }
        }
    }
}

.profile-modal {
    .modal-content {
        border-radius: 25px;
    }

    .modal-dialog {
        margin-right: 30px;
        margin-top: 80px;
    }

    .modal-body {
        position: relative;
        .header {
            background: linear-gradient(181.17deg, #fd3051 0.91%, #ffa42e 163.51%);
            position: absolute;
            top: -10px;
            left: -15px;
            border-bottom: none;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $theme-white;
            cursor: pointer;
        }

        .profile-container {
            height: calc(100vh - 150px);
            overflow-y: scroll;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        h6 {
            color: $theme-orange;
        }

        .img-container {
            .img-holder {
                width: 100px;
                height: 100px;
                border-radius: 50%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 50%;
                }
            }
        }

        .link-container {
            .icon {
                font-size: 16px;
                color: $theme-grey;
            }

            ul {
                padding: 0;
                li {
                    list-style: none;

                    span {
                        width: 30px;
                        height: 30px;
                        background-color: $theme-background;
                        display: flex;
                        text-align: center;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                    }

                    a,
                    .nav-link {
                        color: $theme-grey;
                        font-size: 14px;
                        list-style: none;
                    }
                }
            }
        }
    }
}

.notes-modal {
    .modal-content {
        border-radius: 25px;
    }

    .modal-body {
        position: relative;
        .header {
            background: linear-gradient(181.17deg, #fd3051 0.91%, #ffa42e 163.51%);
            position: absolute;
            top: -10px;
            right: -15px;
            border-bottom: none;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $theme-white;
            cursor: pointer;
        }

        h6 {
            font-weight: 500;
            color: $theme-blue;
        }

        p {
            font-size: 16px;
        }
    }
}

.move-box-modal,
.add-client-popup,
.logout-modal,
.delete-account-modal,
.add-group-modal,
.add-box-modal,
.calendar-modal {
    .modal-content {
        border-radius: 25px;
    }

    input:disabled {
        background-color: white;
    }

    .modal-body {
        position: relative;
        padding: 0;

        .download-csv {
            color: $theme-orange;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            &:hover {
                text-decoration: underline;
            }
        }

        .header {
            background: linear-gradient(181.17deg, #fd3051 0.91%, #ffa42e 163.51%);
            position: absolute;
            top: -10px;
            right: -15px;
            border-bottom: none;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $theme-white;
            cursor: pointer;
        }

        h6 {
            font-weight: 500;
            color: $theme-blue;
        }

        p {
            font-size: 16px;
        }

        button,
        .btn,
        .btn-primary {
            width: 185px;
        }

        .no-bg {
            background: transparent;
            border: 1px solid $theme-orange;
            color: $theme-orange;
        }
    }

    &.force-reset-password {
        span {
            font-size: 16px;
        }
        .btn-common {
            width: 100%;
        }
    }
}

.calendar-modal {
    .btn-filter {
        height: 55px;
    }

    .react-calendar {
        border: none;
    }

    .react-calendar__navigation__arrow {
        &.react-calendar__navigation__prev-button{
            &:hover{
                background: transparent;
            }
        }

        &.react-calendar__navigation__next-button{
            &:hover{
                background: transparent;
            }
        }
    }

    .react-calendar__month-view__days{
        button:enabled:hover{
            background: $theme-orange;
            color: $theme-white;
        }

        button{
            &:focus{
                background: $theme-orange;
                color: $theme-white;
            }
        }
    }


    .react-calendar__navigation {
        button {
            &:nth-child(1) {
                width: 80px;
                height: 48px;
                background: $theme-background;
                border-radius: 50%;
                color: $theme-orange;
            }

            &:nth-child(2) {
                // visibility: hidden;
                &:focus {
                    background: transparent;
                }
            }

            &:nth-child(3) {
                span {
                    font-weight: 500;
                    color: $theme-black;
                    font-size: 18px;
                }
            }

            &:nth-child(4) {
                // visibility: hidden;
                &:focus {
                    background: transparent;
                }
            }

            &:nth-child(5) {
                width: 80px;
                height: 48px;
                background: $theme-background;
                border-radius: 50%;
                color: $theme-orange;
            }
        }
    }

    .react-calendar__tile {
        background-color: $theme-background;
        border: 5px solid white;
        padding: 0;
    }

    .react-calendar__tile--active {
        background-color: $theme-orange !important;
        color: $theme-white;
    }

    button {
        height: 45px;
        border: 1px solid transparent;
    }

    .react-calendar__navigation__label {
        border: none;
        outline: none;
        box-shadow: none;
        background: transparent !important;

        &:hover,
        &:focus {
            background: transparent !important;
            border: none;
            outline: none;
            box-shadow: none;
        }
    }
}

.pdf-modal,
.player-modal,
.image-modal {
    h6 {
        font-size: 14px;
        color: $theme-white;
    }

    .br-10 {
        border-radius: 10px;
    }

    .modal-content {
        border-radius: 25px;
        border: none;
    }

    .modal-body {
        position: relative;
        padding: 0;

        .header {
            background: $theme-white;
            position: absolute;
            top: -10px;
            right: -15px;
            z-index: 2;
            border-bottom: none;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $theme-black;
            cursor: pointer;
        }
    }
}

.player-modal {
    .br-20 {
        border-radius: 20px;
    }
    .custom-player {
        height: auto;
    }
}

.pdf-modal {
    .pdf-viewer {
        height: 80vh;
        overflow: scroll;
        border-radius: 20px;

        &::-webkit-scrollbar {
            display: none;
        }

        .btn-page-change {
            padding: 0;
            width: 40px;
            height: 40px;
            background: $theme-light-grey;
            color: $theme-dark-grey;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        #my-pdf {
            width: 100% !important;
            height: 100% !important;
        }

        canvas {
            height: 100% !important;
        }
    }

    .file-viewer {
        #txt-renderer,
        .hzuSLf {
            background: white;
        }

        #pdf-renderer {
            overflow-y: unset;
        }

        #proxy-renderer {
            height: 555px;
            min-height: 555px;
            max-height: 555px;
            &::-webkit-scrollbar {
                display: none;
            }
        }

        #pdf-download,
        #pdf-zoom-out,
        #pdf-zoom-in,
        #pdf-zoom-reset {
            display: none;
        }

        #pdf-toggle-pagination{
            background: white;
            margin-right: 20px;
        }

        button:disabled{
            border: 1px solid transparent !important;
        }

        #pdf-pagination {
            #pdf-pagination-prev {
                background: white;
            }
            #pdf-pagination-next {
                background: white;
                margin: 0 5px 0 5px;
            }
        }
    }
}


.move-box-modal{
   .form-select{
    box-shadow: none;
    outline: none;
    border: 1px solid grey;
    font-size: 14px;
   }
}